import { Article } from '@/Article';
import { Divider } from '@/Divider';
import { ImageArticle } from '@/ImageArticle';
import { Seo } from '@/layout/Seo';
import Slider from '@/Slider';
import { Tag } from '@/Tag';
import { css } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';
import {
    GatsbyImage,
    getImage,
    IGatsbyImageData,
    ImageDataLike,
    StaticImage,
} from 'gatsby-plugin-image';
import { Fragment } from 'react';
// eslint-disable-next-line
import { SwiperSlide } from 'swiper/react';

const sliderStyles = css`
    margin-bottom: 32px;

    .swiper-slide {
        width: 300px;

        :first-of-type {
            margin-left: 16px;
        }

        :last-of-type {
            margin-right: 16px;
        }
    }

    .gatsby-image-wrapper {
        border-radius: 32px;
    }

    @media (min-width: 1024px) {
        margin-bottom: 64px;

        .swiper-slide {
            width: 400px;

            :first-of-type {
                margin-left: 32px;
            }

            :last-of-type {
                margin-right: 32px;
            }
        }
    }
`;

export default function Technology() {
    type DataTypes = {
        allFile: {
            nodes: {
                childImageSharp: ImageDataLike;
                name: string;
            }[];
        };
    };

    const data: DataTypes = useStaticQuery(graphql`
        query technologySlider {
            allFile(filter: { relativeDirectory: { eq: "about/technology/slider" } }) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 400, height: 300)
                    }
                }
            }
        }
    `);

    const images = data.allFile.nodes.map(
        node => getImage(node.childImageSharp) as IGatsbyImageData
    );

    const sliderSettings = {
        spaceBetween: 16,
        slidesPerView: 'auto' as const,
        breakpoints: {
            1024: {
                spaceBetween: 32,
            },
        },
    };

    return (
        <Fragment>
            <Seo
                title="Technology | Simhairy Dental"
                description="From 3D technology, to the affordability from all corners. We are always at
                        the cutting edge of dentistry. Making it easy in every way imaginable."
            />
            <Article>
                <Tag text="about" />
                <h2>Technology</h2>
                <StaticImage
                    src="../../../assets/images/about/technology/hero.jpg"
                    alt="simhairy dental technology"
                    layout="fullWidth"
                />
                <ImageArticle>
                    <StaticImage
                        src="../../../assets/images/about/technology/camera.jpg"
                        alt="Interoral Camera device"
                        width={500}
                        height={500}
                    />
                    <div>
                        <h2>Interoral Camera</h2>
                        <p>
                            With the help of illumination, an intraoral camera is used to take
                            videos and the still camera is used to capture the image of the
                            condition of the teeth which helps in the assessment and diagnosis of
                            plaque formation, dental cavities, gum diseases, chipped or cracked
                            tooth, broken dental fillings and other oral conditions. It’s the
                            easiest way to visualize your oral health.
                        </p>
                    </div>
                </ImageArticle>
                <Divider />
                <ImageArticle right>
                    <StaticImage
                        src="../../../assets/images/about/technology/cone.jpg"
                        alt="3d xray kavo machine"
                        width={500}
                        height={500}
                    />
                    <div>
                        <h2>Conebeam / 3D X-Ray</h2>
                        <p>
                            The OP 3D system from KaVo is the newest member of the legendary
                            ORTHOPANTOMOGRAPH™ product family; it respects the legacy, yet renews
                            the meaning of image quality, efficiency and ease-of-use.
                        </p>
                        <p>
                            For over a century, the KaVo name has been synonymous with high-quality
                            and dependable products for dental professionals. Leveraging over 5
                            decades of the innovation and successes of OP, KaVo now confidently adds
                            this popular and reliable system to its trusted bands.
                        </p>
                    </div>
                </ImageArticle>
                <Divider />
                <ImageArticle>
                    <StaticImage
                        src="../../../assets/images/about/technology/xray.jpg"
                        alt="digital xray machine"
                        width={500}
                        height={500}
                    />
                    <div>
                        <h2>Digital X-Rays</h2>
                        <p>
                            Dr. Frank Mac is a Board Certified Oral and Maxillofacial Surgeon who
                            has been practicing for over 25 years. When Dr. Mac completed his Doctor
                            of Dental Surgery degree from the University of California, San
                            Francisco, he immediately commissioned as a US Navy Dental Officer. He
                            then went on to specialize in Oral Surgery at Walter Reed Medical Center
                            in Bethesda, Maryland. His time there treating trauma to soldiers,
                            Marines and sailors gave him ample experiences in head and facial
                            reconstruction. Dr. Mac retired from the Navy and now welcomes the
                            opportunity to help manage all your surgical needs from simple dental
                            extractions to oral reconstruction with dental implants and bone grafts.
                        </p>
                    </div>
                </ImageArticle>
            </Article>
            <Divider top />
            <Slider sliderSettings={sliderSettings} css={sliderStyles}>
                {images.map((image, i) => (
                    <SwiperSlide key={i}>
                        <GatsbyImage image={image} alt="simhairy dental technology" />
                    </SwiperSlide>
                ))}
            </Slider>
        </Fragment>
    );
}
